<template>
  <div v-if="loaded">
    <div class="subheader pb-5 subheader-transparent" id="kt_subheader">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap pl-0 py-0">
        <div class="d-flex align-items-center flex-wrap mr-1">
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <h4 class="text-dark font-weight-bolder my-1 mr-5">{{ $t('offer.Offers_From_Partners') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="card card-custom">
          <div class="px-4 pb-4 pt-6">
            <h6 class="card-label font-weight-bolder mb-4">{{ $t('offer.Filters') }}</h6>

            <div class="mb-1">{{ $t('offer.Tags') }}:</div>
            <vSelect v-model="filters.tags_ids" @search="TagsList" class="form-control-select mb-4" label='name' :reduce='name => name.id' multiple :options="tags" :placeholder="$t('offer.Select')">
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <!-- {{ $t('offer.Not_Found') }} <em>{{ search }}</em>. -->
                  {{ $t('offer.Not_Found', {tag: search}) }}
                </template>
                <em style="opacity: 0.5;" v-else>{{ $t('offer.Enter_Tag_Name') }}</em>
              </template>
            </vSelect>

            <div class="mb-1">{{ $t('offer.Partners') }}:</div>
            <input class="form-control mb-4" v-model="filters.partner_name" :placeholder="$t('offer.Enter_Partner_Name')">
            <!-- <vSelect v-model="space" class="mb-4" label='name' :reduce='name => name.id' multiple :options="spaces"></vSelect> -->

            <div class="mb-1">{{ $t('offer.Spaces') }}:</div>
            <vSelect v-model="filters.location_ids"
                     class="form-control-select mb-4" label='name'
                     :reduce='name => name.id' multiple
                     :options="locationsList"
                     :placeholder="$t('offer.Select')"></vSelect>

            <a class="btn btn-block btn-light-primary mt-6" @click="fetch()">
              {{ $t('offer.Apply') }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="card">
          <div class="row px-6 py-2" v-if="offers.length > 0">

            <div class="col-xl-4 p-2" v-for="item in offers" :key="item.id">
              <router-link :to="'/offers/' + item.id" v-slot="{ href, navigate }" class="hover-effect" :title="item.name">
                <a :href="href" @click="navigate">
                  <div class="card card-custom bgi-no-repeat bgi-size-cover offer-card"
                       style="height: 220px; background-position: center;"
                       :style="{'background-image': 'url(' + setImgByScreenWidth(item.imageUrl) + ')'}">

                    <div class="card-body d-flex align-items-start flex-column justify-content-end p-5">
                      <span class="text-light font-weight-bolder font-size-h6 text-truncate"  style="max-width: 100%;">{{ item.name }}</span>
                      <span v-if="item.discount" class="label label-md label-danger label-inline font-weight-bolder mt-1">{{ $t('offer.Discount') }} {{ item.discount }}%</span>
                    </div>

                  </div>
                </a>
              </router-link>
            </div>

          </div>

          <div class="row px-6 py-2" v-if="offers.length <= 0">
            <div class="col-xl-12 p-2 font-size-h6 font-weight-bolder">{{ $t('offer.Offers_Empty') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import api from '@/plugins/api';
import vSelect from 'vue-select';

let cookie = require('vue-cookie');

export default {
  components: { vSelect },
  computed: {
    ...mapGetters({
      loaded: 'offers/loaded',
      offers: 'offers/offers',
      partners: 'offers/partners',
      tags: 'offers/tags',
      // locations: 'offers/locations',
      locationsList: 'spaces/list',
    })
  },
  data() {
    return {
      filters: {
        tags_ids: null,
        partner_name: null,
        location_ids: null,
      },
    }
  },
  mounted() {
    // this.LocationList();
    // this.fetch();
  },
  watch: {
    locationsList: {
      handler(locations) {
        if (locations) {
          this.setLocationList();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('offers', [
      'PartnersList',
      'LocationList',
    ]),
    setLocationList() {
      let ids = this.locationsList.map(item => item.id);
      this.LocationList(ids.join(',')).then(() => {
        this.filters.location_ids = ids;
        this.fetch();
      });
    },
    fetch() {
      if (this.filters.location_ids.length > 0) this.$store.dispatch('offers/OffersList', this.filters);
    },
    TagsList(search) {
      this.$store.dispatch('offers/TagsList', search);
    },
    setImgByScreenWidth(url) {
      return url.replace('325x-', '600x-')
    }
  }
}
</script>

<style>
  .hover-effect div.card-custom {
    transition: filter 0.3s ease;
  }
  .hover-effect:hover div.card-custom {
    filter: grayscale(0.2);
    transition: filter 0.3s ease;
  }
  .offer-card {
    position: relative;
  }
  .offer-card .card-body {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #00000000 0%, #0000001A 64%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
  }
  .form-control-select .vs__dropdown-toggle {
    border-color: #E4E6EF;
    padding: 0.63rem 1rem;
  }
  .form-control-select .vs__dropdown-toggle .vs__selected-options {
    padding: 0;
  }
  .form-control-select .vs__dropdown-toggle .vs__selected {
    margin: .1rem .4rem .1rem 0;
    border: none;
    color: #3f4254;
    background: #f3f6f9;
    padding: .25rem .75rem;
  }
  .form-control-select .vs__dropdown-toggle .vs__search {
    margin-top: 0;
    padding: 0;
    color: #B5B5C3;
  }
</style>
